<template>
  <el-dialog
    title="申请记录"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="1000px"
    height="1000px"
  >
    <div class="LoanDetails">
      <table border="1" cellpadding="0">
        <tbody>
          <tr>
            <td>主体名称</td>
            <td>{{historyList. name }}</td>
            <td>申请产品</td>
            <td>{{ historyList.title }}</td>
          </tr>
          <tr>
            <td>主体类型</td>
            <td>{{ typesName }}</td>
            <td>统一社会信用代码</td>
            <td>{{ historyList.idCardNo }}</td>
          </tr>

          <tr>
            <td>法人姓名</td>
            <td>{{ historyList.legalPersonName }}</td>
            <td>法人身份证号</td>
            <td>{{ historyList.legalPersonNo }}</td>
          </tr>

          <tr>
            <td>地址</td>
            <td colspan="3">
              {{ historyList.area }}
            </td>
          </tr>

          <tr>
            <td>代理人</td>
            <td>{{ historyList.agentName }}</td>
            <td>代理人身份证号</td>
            <td>{{ historyList.agentNo }}</td>
          </tr>
          <tr>
            <td>手机号</td>
            <td>{{ historyList.phone }}</td>
          </tr>

          <tr>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td>年经营收入</td>
            <td>{{ historyList.income }}</td>
            <td>实际经营年限</td>
            <td>{{ historyList.operatYear }}年</td>
          </tr>

          <tr>
            <td>经营内容</td>
            <td colspan="3">
              {{ historyList.operatIntorducation }}
            </td>
          </tr>

          <tr>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td>贷款金额</td>
            <td colspan="3">{{ historyList.money }}元</td>
          </tr>

          <tr>
            <td>资金用途</td>
            <td colspan="3">{{ historyList.use }}</td>
          </tr>

          <tr>
            <td colspan="4"></td>
          </tr>

          <tr>
            <td>审批状态</td>
            <td>{{ statusName }}</td>
            <td>申请时间</td>
            <td>{{approveTime}}</td>
          </tr>
        </tbody>
      </table>
      <!-- 营业执照 -->
      <div class="BusinessLicense">
        <span>营业执照</span>
        <div class="BusinessLicenseImg">
          <img :src="historyList.licenseImg" alt="" />
        </div>
      </div>
      <!-- 法人身份证 -->
      <div class="LegalPersonIDCard">
        <div class="Left">
          <p class="title"><span>法人身份证人面像</span></p>
          <img :src="historyList.userCardFrontImg" alt="" />
        </div>
        <div class="Right">
          <p class="title"><span>法人身份证国徽像</span></p>
          <img :src="historyList.userCardBackImg" alt="" />
        </div>
      </div>
      <!-- 其他附件 -->
      <div class="OtherAccessories">
        <span class="title">其他附件</span>
        <div class="BusinessLicenseItem" v-if="historyList.files" >
          <div
            class="Item"
            v-for="(item, index) in JSON.parse(historyList.files)"
            :key="index"
          >
            <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
              <div slot="error" class="image-slot">
                <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
              </div>
            </el-image>
            <span>
              {{ item.FilesName }}
            </span>
          </div>
        </div>
        <div class="BusinessLicenseItem" v-else>
          暂无相关数据
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { operateDetails } from "@/api/RuralFinance";
export default {
  data() {
    return {
      dialogVisible: true,
      historyList: [],
      //申请状态
      statusType: [
        {
          label: "驳回",
          value: "-1",
        },
        {
          label: "草稿",
          value: "0",
        },
        {
          label: "审批中",
          value: "1",
        },
        {
          label: "通过",
          value: "2",
        },
      ],
      //主体类型
      types: [
        {
          label: "集体经济组织",
          value: "3",
        },
        {
          label: "农业企业",
          value: "41",
        },
        {
          label: "家庭农场",
          value: "42",
        },
        {
          label: "合作社",
          value: "43",
        },
      ],
      //主体类型名字
      typesName: "",
      statusName: "",
    };
  },

  mounted() {},

  methods: {
    handleClose(done) {
      this.$emit("closeDialog", false);
    },

    //回显详情数据
    async init(val) {
      // console.log(val,"传过来的")
      let params = {
        applyId: val,
      };
      let res = await operateDetails(params);
      this.historyList = res.data;
      let Value = this.historyList.userType;
      let Value2 = this.historyList.status;
      let label = "";
      let label2 = "";
      this.types.filter((item, index) => {
        if (Value == item.value) {
          return (label = item.label);
        }
      });
      this.typesName = label;
      this.statusType.filter((item, index) => {
        if (Value2 == item.value) {
          return (label2 = item.label);
        }
      });
      console.log(this.statusName);
      this.statusName = label2;
    },
  },
};
</script>

<style lang="less" scoped>
.LoanDetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    tr {
      td {
        width: 80px;
        height: 30px;
        text-align: center;
      }
    }
  }
  /*营业执照*/
  .BusinessLicense {
    width: 100%;
    height: 100px;
    display: flex;
    span {
      font-size: 20px;
      font-weight: bold;
      margin-top: 20px;
    }
    .BusinessLicenseImg {
      margin-left: 125px;
      padding: 5px;
      img {
        width: 200px;
        height: 80px;
      }
    }
  }
  /*法人身份证*/
  .LegalPersonIDCard {
    width: 100%;
    display: flex;
    .Left {
      width: 50%;
      display: flex;
      img {
        width: 200px;
        height: 100px;
        margin-left: 50px;
      }
      p {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .title {
        font-size: 15px;
        font-weight: bold;
        span {
          font-size: 20px;
          font-weight: bold;
          margin-top: 20px;
        }
      }
    }
    .Right {
      width: 50%;
      display: flex;
      justify-content: space-around;
      img {
        width: 200px;
        height: 100px;
      }
      p {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .title {
        font-size: 15px;
        font-weight: bold;
        span {
          font-size: 20px;
          font-weight: bold;
          margin-top: 20px;
        }
      }
    }
  }
 /*其它附件*/
 .OtherAccessories {
  width: 100%;
  height: 100px;
  display: flex;
  .title {
    width: 100px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-right: 100px;
  }
  .BusinessLicenseItem {
    width: 100%;
    display: flex;
    div {
      display: flex;
      flex-direction: column;
      margin: 0px 5px;
      i {
        font-size: 50px;
      }
    }
    .Item {
      width: 150px;
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      position: relative;
      padding: 10px;
      .el-image {
        width: 150px;
        height: 85px;
        background: #e6e3e3;
        img {
          width: 200px;
          height: 50px;
        }
        i {
          font-size: 50px;
          font-weight: bold;
          margin-top: 20px;
        }
      }
      span {
        margin-top: 5px;
      }
      .del {
        width: 20px;
        height: 20px;
        border: solid 1px black;
        border-radius: 50px;
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 5000;
        text-align: center;
        line-height: 20px;
      }
    }
  }
}
}
</style>
